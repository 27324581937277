'use client';

import { useRouter } from 'next/navigation';

import MaxWidthContainer from '@/components/dom/max-width-container';
import { LoginSignupForm, OpenWithTypes } from '@/components/forms/login-signup-form';
import { useGlobalState } from '@/components/global/global-state';

export const LoginPage = () => {
    const { push } = useRouter();
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;

    if (currentUserLoading) {
        return <></>;
    }

    if (currentUser) {
        push('/app/start');

        return null;
    }

    return (
        <MaxWidthContainer className="py-12">
            <div className="w-1/2 mx-auto">
                <LoginSignupForm
                    {...{
                        allowSocialLogin: true,
                        openWith: OpenWithTypes.LOGIN,
                        trackingProperties: {},
                    }}
                />
            </div>
        </MaxWidthContainer>
    );
};
